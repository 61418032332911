import React, { Suspense, useEffect } from 'react';
import { createBrowserRouter, redirect, useNavigate } from 'react-router-dom';
import { isAuthorized } from './identity';
import ErrorPage from './pages/error-page';
import PageLoader from './components/page-layout/page-loader';
import PageLayout from './components/page-layout/page-layout';

//Lazy-loaded components
const Home = React.lazy(() => import('./pages/home/home'));
const Customers = React.lazy(() => import('./pages/customers/customers'));
const Mappings = React.lazy(() => import('./pages/mappings/mappings'));
const PreviewMappings = React.lazy(() => import('./pages/preview/preview'));
const Resources = React.lazy(() => import('./pages/resources/resources'));
const Fields = React.lazy(() => import('./pages/fields/fields'));
const Lookups = React.lazy(() => import('./pages/lookups/lookups'));
const CustomerFields = React.lazy(() => import('./pages/customer-fields/customer-fields'));
const CustomerLookups = React.lazy(() => import('./pages/customer-lookups/customer-lookups'));
const Users = React.lazy(() => import('./pages/users/users'));
const Signin = React.lazy(() => import('./pages/signin/signin'));
const Activate = React.lazy(() => import('./pages/activate/activate'));
const Recover = React.lazy(() => import('./pages/recover/recover'));
const Reset = React.lazy(() => import('./pages/reset/reset'));
const Signout = React.lazy(() => import('./pages/signout/signout'));
const Debug = React.lazy(() => import('./pages/debug/debug'));
const NotFound = React.lazy(() => import('./pages/not-found'));
const GraphiQL = React.lazy(() => import('./pages/graphiql/graphiql'));
const SourceFields = React.lazy(() => import('./pages/source-fields/source-fields'));
const SourceLookups = React.lazy(() => import('./pages/source-lookups/source-lookups'));
const SourceData = React.lazy(() => import('./pages/source-data/source-data'));
const SeedRequirements = React.lazy(() => import('./pages/requirements/seed-requirements'));
const CustomerRequirements = React.lazy(() => import('./pages/requirements/customer-requirements'));
const MappingRules = React.lazy(() => import('./pages/mapping-rules/mapping-rules'));
const RuleTool = React.lazy(() => import('./pages/rule-tool/rule-tool'));
const Tools = React.lazy(() => import('./pages/tools/tools'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Start />,
    errorElement: <ErrorPage />
  },
  {
    path: '/home',
    element: (
      <PageLayout title="Home">
        <Suspense fallback={<PageLoader />}>
          <Home />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/customers',
    element: (
      <PageLayout title="Customers">
        <Suspense fallback={<PageLoader />}>
          <Customers />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/mappings',
    element: (
      <PageLayout title="Mappings">
        <Suspense fallback={<PageLoader />}>
          <Mappings />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/preview',
    element: (
      <PageLayout title="Preview Mappings">
        <Suspense fallback={<PageLoader />}>
          <PreviewMappings />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/mappingrules',
    element: (
      <PageLayout title="Mapping Rules">
        <Suspense fallback={<PageLoader />}>
          <MappingRules />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/ruletool',
    element: (
      <PageLayout title="Rule Tool">
        <Suspense fallback={<PageLoader />}>
          <RuleTool />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/resources',
    element: (
      <PageLayout title="Resources">
        <Suspense fallback={<PageLoader />}>
          <Resources />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/fields',
    element: (
      <PageLayout title="Fields">
        <Suspense fallback={<PageLoader />}>
          <Fields />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/customerfields',
    element: (
      <PageLayout title="Customer Fields">
        <Suspense fallback={<PageLoader />}>
          <CustomerFields />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/lookups',
    element: (
      <PageLayout title="Lookups">
        <Suspense fallback={<PageLoader />}>
          <Lookups />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/customerlookups',
    element: (
      <PageLayout title="Customer Lookups">
        <Suspense fallback={<PageLoader />}>
          <CustomerLookups />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/sourcefields',
    element: (
      <PageLayout title="Source Fields">
        <Suspense fallback={<PageLoader />}>
          <SourceFields />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/sourcelookups',
    element: (
      <PageLayout title="Source Lookups">
        <Suspense fallback={<PageLoader />}>
          <SourceLookups />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/requirements/seed',
    element: (
      <PageLayout title="Seed Requirements">
        <Suspense fallback={<PageLoader />}>
          <SeedRequirements />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/requirements/customer',
    element: (
      <PageLayout title="Customer Requirements">
        <Suspense fallback={<PageLoader />}>
          <CustomerRequirements />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/sourcedata',
    element: (
      <PageLayout title="Source Data">
        <Suspense fallback={<PageLoader />}>
          <SourceData />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/users',
    element: (
      <PageLayout title="Users">
        <Suspense fallback={<PageLoader />}>
          <Users />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/tools',
    element: (
      <PageLayout title="Tools">
        <Suspense fallback={<PageLoader />}>
          <Tools />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/graphiql',
    element: (
      <PageLayout title="GraphiQL">
        <Suspense fallback={<PageLoader />}>
          <GraphiQL />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />,
    loader: protect
  },
  {
    path: '/signin',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Signin />
      </Suspense>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: '/activate',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Activate />
      </Suspense>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: '/recover',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Recover />
      </Suspense>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: '/reset',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Reset />
      </Suspense>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: '/signout',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Signout />
      </Suspense>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: '/debug',
    element: (
      <PageLayout title="Diagnostic Info">
        <Suspense fallback={<PageLoader />}>
          <Debug />
        </Suspense>
      </PageLayout>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: '*',
    element: (
      <PageLayout title="Not Found">
        <Suspense fallback={<PageLoader />}>
          <NotFound />
        </Suspense>
      </PageLayout>
    )
  }
]);

function Start() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized()) {
      navigate('/home', { replace: true });
    } else {
      navigate('/signin', { replace: true });
    }
  }, []);

  return null;
}

function protect() {
  if (!isAuthorized()) {
    return redirect('/signin');
  }

  return null;
}

export default router;
