import {
  Icon,
  IconBoxAlignTop,
  IconBoxSeam,
  IconBrandGraphql,
  IconBug,
  IconFileSearch,
  IconHome,
  IconListSearch,
  IconLogout,
  IconMap,
  IconPuzzle2,
  IconRuler2,
  IconRulerMeasure,
  IconSandbox,
  IconTools,
  IconUserCircle,
  IconUsersGroup
} from '@tabler/icons-react';

export interface AppLink {
  icon: Icon;
  label: string;
  link: string;
}

export const homeLink: AppLink = {
  icon: IconHome,
  label: 'Home',
  link: '/home'
};
export const mappingLinks: AppLink[] = [
  { icon: IconMap, label: 'Mappings', link: '/mappings' },
  { icon: IconRuler2, label: 'Mapping Rules', link: '/mappingrules' },
  { icon: IconRulerMeasure, label: 'Rule Tool', link: '/ruletool' },
  { icon: IconSandbox, label: 'Requirements', link: '/requirements/seed' },
  { icon: IconPuzzle2, label: 'Customer Requirements', link: '/requirements/customer' }
];
export const metadataLinks: AppLink[] = [
  { icon: IconBoxSeam, label: 'Resources', link: '/resources' },
  { icon: IconBoxAlignTop, label: 'Fields', link: '/fields' },
  { icon: IconListSearch, label: 'Lookups', link: '/lookups' }
];
export const customerMetadataLinks: AppLink[] = [
  { icon: IconUsersGroup, label: 'Customers', link: '/customers' },
  { icon: IconBoxAlignTop, label: 'Customer Fields', link: '/customerfields' },
  { icon: IconListSearch, label: 'Customer Lookups', link: '/customerlookups' }
];
export const sourceDataLinks: AppLink[] = [
  { icon: IconFileSearch, label: 'Source Data', link: '/sourcedata' },
  { icon: IconBoxAlignTop, label: 'Source Fields', link: '/sourcefields' },
  { icon: IconListSearch, label: 'Source Lookups', link: '/sourcelookups' },
  // where did media go?
  // { icon: IconFileSearch, label: 'Source Media', link: '/sourcemedia' },
];
export const adminLinks: AppLink[] = [
  { icon: IconUserCircle, label: 'Users', link: '/users' },
  { icon: IconTools, label: 'Tools', link: '/tools' }
];
export const otherLinks: AppLink[] = [
  { icon: IconBug, label: 'Diagnostic Info', link: '/debug' },
  { icon: IconBrandGraphql, label: 'GraphiQL', link: '/graphiql' },
  { icon: IconLogout, label: 'Sign Out', link: '/signout' }
];
