import { Divider, Navbar, Menu, ScrollArea } from '@mantine/core';
import { useClickOutside, useEventListener, useMergedRef } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import { adminLinks, AppLink, customerMetadataLinks, homeLink, mappingLinks, metadataLinks, otherLinks, sourceDataLinks } from '../app-link/app-link';
import { useCurrentUser } from '../../app-state';


function NavbarLink({ icon: Icon, label, link }: AppLink) {
  return (
    <Menu.Item component={Link} to={link} icon={<Icon stroke={1.5} />} style={{ textDecoration: 'none', background: 'inherit' }}>
      {label}
    </Menu.Item>
  );
}

interface AppNavbarProps {
  opened: boolean;
  setOpened: Function;
}

export default function AppNavbar({ opened, setOpened }: AppNavbarProps) {

  const user = useCurrentUser();

  const clickOutsideRef = useClickOutside(() => setOpened(false));
  const clickInsideRef = useEventListener('click', () => setOpened(false));
  const combinedRef = useMergedRef(clickInsideRef, clickOutsideRef);
  
  return (<Navbar w={240} style={{ display: opened ? 'initial' : 'none' }} ref={combinedRef}>
    <Menu>
      <ScrollArea h="100%">
        <NavbarLink icon={homeLink.icon} label={homeLink.label} link={homeLink.link} />

        <Divider label="Mapping" labelPosition="center" />
        {mappingLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}

        <Divider label="Metadata" labelPosition="center" />
        {metadataLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}

        <Divider label="Customer Metadata" labelPosition="center" />
        {customerMetadataLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}

        <Divider label="Source Data" labelPosition="center" />
        {sourceDataLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}
        
        {user?.isAMP() && <>
          <Divider label="Admin" labelPosition="center" />
          {adminLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}
        </>}

        <Divider label="Other" labelPosition="center" />
        {otherLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}
      </ScrollArea>
    </Menu>
  </Navbar>);
}