import { ApolloProvider } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { client } from './apollo';
import CheckForUpdate from './components/check-for-update/check-for-update';
import { useAuthorizationExpired } from './identity';
import './index.css';
import router from './router';
import themeOverride from './theme-override';
import AppStateProvider from './app-state';
import "@tabler/icons-webfont/dist/tabler-icons.min.css";

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <MantineProvider withGlobalStyles withNormalizeCSS theme={themeOverride}>
      <ModalsProvider>
        <ApolloProvider client={client}>
          <AppStateProvider>
            <RouterProvider router={router} />
          </AppStateProvider>
        </ApolloProvider>
      </ModalsProvider>
    </MantineProvider>
    <CheckForExpiredAuth />
    <CheckForUpdate />
    {/**
     * Can't figure out a way to force font-display: 'block' on the icon font due to
     * the way it's imported so instead rendering a hidden icon to force the font to
     * pull in before anything gets a chance to be displayed. This prevents 'boxes'
     * from being rendered while waiting on the font to load (hopefully)
     */}
    <i className="ti" style={{ display: 'hidden', height: 0, width: 0 }} />
  </>
);


function CheckForExpiredAuth() {
  useAuthorizationExpired(() => {
    router.navigate('/signin');
  });

  return null;
}
